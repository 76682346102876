<template>
  <div class="d-flex justify-content-center">
    <div class="invoice-parent">
      <div class="d-flex justify-content-center parent-container animate__animated animate__fadeIn">

        <div class="d-flex flex-column">
          <div class="intro-container my-container-light p-3">

            <h5 class="claim-title" tabindex="0">Añade Facturas aquí</h5>
            <p class="claim-text ">
              Sube documentos con facturas y extraeremos los campos relevantes.
              A continuación podrás revisar los campos sugeridos.
              Comprueba que todos los campos sean correctos.

            </p>
            <h5 class="my-subtitle" tabindex="0">Importar Documento</h5>
            <p class="claim-text ">
              Sube un documento tipo <b>PDF</b>, <b>Word</b> o <b>Excel</b> que contenga las facturas que quieras importar.
            </p>

            <vueform>
              <FileElement
                  :upload-temp-endpoint="onUpload"
                  name="fileUploader"
                  :drop="true"
                  accept=".pdf"
                  :auto="false"
                  :format-data="formatFileData"
              />
            </vueform>

          </div>
        </div>

      </div>

      <div class="my-message my-2 py-2 text-center mb-3" v-if="xUserPending.length">

        Antes de continuar tienes que revisar las facturas con estado <b> pdte. revisión</b>

      </div>
      <div class="form-container invoices-list">

        <invoice-list
            :show-status="true"
            @onEdit="onEditClick"
            @onDelete="deleteInvoice"
            :invoices="invoices"
            v-if="invoices.length">

        </invoice-list>

        <Message v-else severity="info" :closable="false">Ninguna factura importada</Message>

      </div>



      <div class="d-flex mx-2 justify-content-end my-3">

        <Button id="btn-invoice-field-return"  @click="onReturnClick"
                class="vf-btn vf-btn-sm mr-1">
          <b-icon icon="skip-backward" class="mr-1" style="padding-top: 2px"></b-icon>volver
        </Button>

        <Button  id="invoice-step-next-btn"  @click="onNextClick"
                 class="vf-btn vf-btn-primary vf-btn-sm"
                 style="width: 120px">siguiente
        </Button>

        <b-tooltip v-if="!invoicesConfirmed"  placement="topright" target="invoice-step-next-btn" triggers="hover">
          Antes de continuar tienes que revisar las facturas con estado <b> pdte. revisión</b>
        </b-tooltip>

      </div>



    </div>



  </div>

</template>

<script>

import { mapGetters } from 'vuex';
import FileUpload from '@/components/primevue/fileUpload';
import InvoiceList from '@/components/invoiceList';

import ApiService from "@/services/api.service";
import {SAVE_INVOICE} from "@/store/actions.type";
import InlineMessage from 'primevue/inlinemessage';
import {
  listToDict,
  logInfo,
  checkStatusProcessing,
  checkStatusCompleted,
  checkStatusFinished,
  checkStatusConfirmed,
} from "@/helpers";
import { GO_TO_STEP, STEPS_ENUM } from '@/store/actions.type'



export default {
  name: 'invoices',
  components: {
    FileUpload,
    InvoiceList,
    InlineMessage,
  },
  data: () => ({
    liveWire: null,
    borrar: null,
    fileUrls: {
      uploadTempFile: "api/invoice/upload",
      removeTempFile: "api/invoice/upload-remove",
    },
    invoices: []
  }),

  watch: {
  },


  computed: {
    ...mapGetters(['claim','lastStep']),


    invoicesConfirmed() {
      return this.invoices.length && this.invoices.reduce((prev,el)=>{
        prev &= checkStatusConfirmed(el['status'])
        return prev
      }, true)
    },

    xOcrPending() {
      return this.invoices.filter(el => checkStatusProcessing(el['status']))
    },


    xUserPending() {
      return this.invoices.filter(el => !checkStatusFinished(el['status']))
    },

  },
  methods: {

    async onReturnClick() {
      await this.$store.dispatch(GO_TO_STEP, STEPS_ENUM.PROFILE)
    },

    async onNextClick() {
      if(!this.invoicesConfirmed) {
        return
      }
      await this.$store.dispatch(GO_TO_STEP, STEPS_ENUM.SIMULATION)
    },

    formatFileData(n,v) {
      return {
        [n]: v
      }
    },

    async loadInvoices() {

      if(!this.claim._id) { //issue: possible solution next tick?
        console.log("ERROR: NO CLAIM ID: $nextTick?")
        await this.$nextTick()
      }

      if(this.claim._id) {
        const { data } = await ApiService.get(`api/claim/${this.claim._id}/invoices`)
        this.invoices = data
        if(this.xOcrPending) {
          if(!this.liveWire){
            this.liveWire = setInterval(async function () {
              await this.pullLiveWire();
            }.bind(this), 5000);
          }
        }
      }else{
        console.log("ERROR: STILL NO CLAIM ID")
      }
    },

    async onUpload(file, el$) {
      let data = el$.form$.convertFormData({ file })
      const resp = await ApiService.post("api/invoice/upload", data)
      const { _id } = resp
      await this.$store.dispatch(SAVE_INVOICE, _id)
      logInfo("Factura importada")
      await this.loadInvoices()
    },

    onEditClick(invoice) {
      this.$router.push(`/invoice/${invoice._id}`)
    },

    async deleteInvoice(invoice) {
      const { _id } = invoice
      await ApiService.delete(`api/claim/${this.claim._id}/invoice/${_id}`)
      logInfo('Factura eliminada')
      await this.loadInvoices()
    },


    //avoid using websocket as client firewall might block ports
    async pullLiveWire() {
      if(this.xOcrPending.length) {
        const qs_ids = this.xOcrPending.map(el=>el._id).join(",")
        const { data } = await ApiService.get(`/api/invoice/livewire?ids=${qs_ids}`)
        const _new = []
        const _dict = listToDict(data, '_id')

        let changed = false
        for(const _invoice of this.invoices) {
          if(_invoice._id in _dict) {
            if(_dict[_invoice._id].status !== _invoice.status) {
              changed = true
              _new.push(_dict[_invoice._id])
            }
          }else{
            _new.push(_invoice)
          }
        }
        if(changed) {
          this.invoices = _new
        }
        if(!this.xOcrPending) {
          clearInterval(this.liveWire) //livewire no longer needed
          this.liveWire = null
        }
      }
    },

  },

  async mounted() {
    await this.loadInvoices()
  },

  beforeDestroy() {
    clearInterval(this.liveWire)
  }


}
</script>
<style>

.claim-text {
  font-family: "reclamatec", serif;
  font-size: 14px;
  color: #757575;
  line-height: 1.325rem;
  font-weight: 300;
}


.claim-title {
  font-family: "reclamatec", serif;
  font-size: 19px;
  color: #333333;
  line-height: 2.625rem;
  font-weight: 600;
}


.invoices-list{
}

.invoice-parent {
  width: 550px;
}

.vf-file-preview-filename {
  font-size: 13px;
  color: cadetblue;
  font-weight: bold;
  max-width: 400px;
}



</style>


